import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';

import { useAllHomeFeedQuery } from '../queries/AllHomeFeed.delio.generated';

export const SECTIONS_PER_PAGE = 4;

export const useAllHomeFeed = () => {
  const {
    coordinates,
    error: coordinatesError,
    loading: coordinatesLoading,
  } = useUserAddressCoordinates();
  const { query, replace } = useRouter();
  const hasMore = useRef(true);
  const [isLoadingMore, setLoadingMore] = useState(false);
  const { t } = useTranslation('common');

  const offset = query.offset ? parseInt(query.offset as string, 10) : 0;

  const initialVariables = useRef({ offset });

  const allHomeFeedResult = useAllHomeFeedQuery({
    variables: {
      coordinates,
      limit: initialVariables.current.offset || SECTIONS_PER_PAGE,
    },
    skip: coordinatesLoading,
    notifyOnNetworkStatusChange: true,
  });

  const { data, fetchMore } = allHomeFeedResult;

  const error = allHomeFeedResult.error ?? coordinatesError;
  const isLoading =
    allHomeFeedResult.loading || coordinatesLoading || isLoadingMore;

  const loadMore = useCallback(async () => {
    if (!hasMore.current) return;

    setLoadingMore(true);

    try {
      const fetchMoreOffset = offset || SECTIONS_PER_PAGE;
      const result = await fetchMore({
        variables: {
          offset: fetchMoreOffset,
          limit: SECTIONS_PER_PAGE,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newSections = fetchMoreResult?.webHomeFeed?.sections || [];

          if (newSections.length === 0) {
            hasMore.current = false;
            return prev;
          }

          return {
            __typename: 'Query',
            webHomeFeed: {
              ...prev.webHomeFeed,
              sections: [...prev.webHomeFeed.sections, ...newSections],
            },
          };
        },
      });

      if (!result?.data?.webHomeFeed?.sections?.length) return;

      await replace(
        { query: { ...query, offset: fetchMoreOffset + SECTIONS_PER_PAGE } },
        undefined,
        { shallow: true }
      );
    } catch (err) {
      captureException(err);
      toast.error(
        t('Something went wrong, please refresh the page and try again.')
      );
    } finally {
      setLoadingMore(false);
    }
  }, [fetchMore, offset, query, replace, t]);

  return useMemo(
    () => ({
      data,
      isLoading,
      error: data ? undefined : error,
      loadMore,
    }),
    [data, error, isLoading, loadMore]
  );
};
