import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Options, useDebouncedCallback } from 'use-debounce';

import { SpinnerIcon } from '@lib/theme/components/SpinnerIcon';

const _debounceTime = 100;

export const InfiniteScroll = ({
  load,
  disabled,
  isLoading,
  debounceOptions,
  debounceTime,
}: {
  load: () => Promise<void>;
  disabled?: boolean;
  isLoading: boolean;
  debounceOptions?: Options;
  debounceTime?: number;
}) => {
  const [ref, inView] = useInView();

  const loadDebounced = useDebouncedCallback(
    load,
    debounceTime ?? _debounceTime,
    debounceOptions ?? {
      leading: true,
    }
  );

  useEffect(() => {
    if (inView && !disabled) {
      loadDebounced();
    }

    return () => {
      loadDebounced.cancel();
    };
  }, [inView, loadDebounced, disabled]);

  return (
    <>
      {isLoading && (
        <div className="flex h-80 justify-center py-4">
          <SpinnerIcon className="size-8" />
        </div>
      )}
      <div ref={ref} className="h-px" />
    </>
  );
};
